import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_o = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - O'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>O</h2>
     <LatQuotesIntro />
     <p><b>Omne sacramentum debet esse de certa scientia</b> - Every oath ought to be of certain knowledge.</p>
<p><b>Omnia delicta in aperto leviora sunt</b> - All crimes (committed) in the open are (considered) lighter.</p>
<p><b>Omnia praesumuntur contra spoliatorem</b> - All things are presumed against a wrongdoer.</p>
<p><b>Omnis innovatio plus novitate perturbat quam utilitate prodeat</b> - Every innovation disturbs more by its novelty than it benefits by its utility.</p>
<p><b>Optima legum interpres est consuetudo</b> - The best interpreter of laws is custom.</p>
<p><b>Optimus interpres rerum est usus</b> - The best interpreter of things is usage.</p>

   </Layout>
  )
}

export default LatQuotes_o
